import * as React from 'react'
import { useEffect } from 'react'
import Nav from 'components/Nav'
import Footer from 'components/Footer'
import 'styles/mainStyle.scss'
import 'styles/utilities.scss'
import 'styles/textures.scss'
import 'styles/slick-slider.scss'

export default function MainLayout({ children }){
  return(
    <>
      <Nav />
      <main>
        {children}
      </main>
      <Footer />
    </>
  )
}
