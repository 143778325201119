import * as React from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Offcanvas from 'react-bootstrap/Offcanvas'
import CartButton from 'common/components/CartButton.js'
import {Link} from 'gatsby'
import { useState } from 'react'
import 'styles/nav.scss'

export default function BfdNavbar() {
	const [show, setShow] = useState(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	return (
		<Navbar expand={false} className="bg-black" sticky='top'>
			<Container>
				<Link to='/search'>
					<img src="/icons/search.svg" alt="" />					
				</Link>

				<Navbar.Brand as={Link} to='/' className='m-0'>
					<img src="/logos/bfd.svg" alt="" height={36} />
				</Navbar.Brand>

				<div>
					<CartButton className="cart d-inline-block px-3">
						<img src="/icons/shopping-bag.svg" alt="" />
					</CartButton>

					<Navbar.Toggle onClick={handleShow} aria-controls="basic-navbar-nav" className='d-inline-block border-0 px-3 py-0'>
						<img src="/icons/toggler.svg" alt="" />
					</Navbar.Toggle>
				</div>

				<Navbar.Offcanvas 
					id={`offcanvasNavbar-expand`}
					aria-labelledby={`offcanvasNavbarLabel-expand`}
					placement="end"
					show={show} 
					onHide={handleClose}
				>
					<Offcanvas.Header closeButton className='p-0'>
						<Offcanvas.Title id={`offcanvasNavbarLabel-expand`} className='invisible px-5'>
							Offcanvas
						</Offcanvas.Title>
					</Offcanvas.Header>

					<Offcanvas.Body className='px-4 py-0 mt-4 d-flex flex-column justify-content-between'>
						<Nav className="px-4">
							<Nav.Link as={Link} to='/drum-software/bfd-player.html' onClick={handleClose} className='d-flex justify-content-between bold' style={{fontSize: 18}}>
								<span>BFD PLAYER</span>
								<span><img src='/icons/arrow-right-bold.svg' alt='arrow' height={16} /></span>
							</Nav.Link>
							
							<Nav.Link as={Link} to='/bfd-player-expansions' onClick={handleClose} className='d-flex justify-content-between'>
								<span>BFD PLAYER EXPANSIONS</span>
								<span><img src='/icons/arrow-right-light.svg' alt='arrow' height={16} /></span>
							</Nav.Link>
							
							{/* <Nav.Link as={Link} to='/groove-packs' onClick={handleClose} className='d-flex justify-content-between'>
								<span>BFD PLAYER GROOVES</span>
								<span><img src='/icons/arrow-right-light.svg' alt='arrow' height={16} /></span>
							</Nav.Link> */}

							<hr/>
							
							<Nav.Link as={Link} to='/drum-software/bfd3.html' onClick={handleClose} className='d-flex justify-content-between bold' style={{fontSize: 18}}>
								<span>BFD3 SOFTWARE</span>
								<span><img src='/icons/arrow-right-bold.svg' alt='arrow' height={16} /></span>
							</Nav.Link>
							
							<Nav.Link as={Link} to='/expansions' onClick={handleClose} className='d-flex justify-content-between'>
								<span>BFD3 EXPANSIONS</span>
								<span><img src='/icons/arrow-right-light.svg' alt='arrow' height={16} /></span>
							</Nav.Link>
							
							<Nav.Link as={Link} to='/groove-packs' onClick={handleClose} className='d-flex justify-content-between'>
								<span>BFD3 GROOVES</span>
								<span><img src='/icons/arrow-right-light.svg' alt='arrow' height={16} /></span>
							</Nav.Link>

							<hr/>
							
							<NavDropdown title="SUPPORT">
								<NavDropdown.Item href="https://forum.bfddrums.com/" target="_blank" rel="noreferrer">FORUM</NavDropdown.Item>
								<NavDropdown.Item as={Link} to='/downloads.html' onClick={handleClose}>DOWNLOADS</NavDropdown.Item>
								<NavDropdown.Item href="https://support.bfddrums.com/en/support/home" target="_blank" rel="noreferrer">KNOWLEDGE BASE</NavDropdown.Item>
								<NavDropdown.Item href="https://support.bfddrums.com/en/support/home" target="_blank" rel="noreferrer">CONTACT SUPPORT</NavDropdown.Item>
							</NavDropdown>

							<hr/>

							<NavDropdown title="COMPANY">
								<NavDropdown.Item as={Link} to='/company/press-release' onClick={handleClose}>PRESS RELEASE</NavDropdown.Item>
								<NavDropdown.Item as={Link} to='/company/about-us.html' onClick={handleClose}>ABOUT US</NavDropdown.Item>
								<NavDropdown.Item href="https://www.inmusicbrands.com/careers/" target="_blank" rel="noreferrer">CAREERS</NavDropdown.Item>
							</NavDropdown>

							<hr/>

							<Nav.Link href="https://profile.inmusicbrands.com/customer/account/login/" target="_blank" rel="noreferrer" className='bold' style={{fontSize: 18}}>ACCOUNT</Nav.Link>
						</Nav>

						{/* FOOTER */}
						<div className='text-end mb-5'>
							{/* BFD LOGO */}
							<Link to='/' onClick={handleClose}>
								<img src='/logos/bfd-black.png' alt='' height={36} />
							</Link>

							{/* SOCIALS */}
							<div className='d-flex mt-4 justify-content-end align-items-center gap-4'>
								<a href="https://www.facebook.com/bfddrums" target="_blank" rel="noreferrer">
									<img src='/icons/facebook-black.svg' alt='' />
								</a>
								<a href="https://twitter.com/bfddrums" target="_blank" rel="noreferrer">
									<img src='/icons/twitter-black.svg' alt='' />
								</a>
								<a href="https://www.instagram.com/bfddrums/" target="_blank" rel="noreferrer">
									<img src='/icons/instagram-black.svg' alt='' />
								</a>
								<a href="https://www.youtube.com/channel/UCaTHRhZ8fAf6iyyZcG2CeOw/featured" target="_blank" rel="noreferrer">
									<img src='/icons/youtube-black.svg' alt='' />
								</a>
							</div>
						</div>
					</Offcanvas.Body>
				</Navbar.Offcanvas>
			</Container>
		</Navbar>
	)
}