exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bfd-player-expansions-americana-brushes-js": () => import("./../../../src/pages/bfd-player-expansions/americana-brushes.js" /* webpackChunkName: "component---src-pages-bfd-player-expansions-americana-brushes-js" */),
  "component---src-pages-bfd-player-expansions-americana-sticks-js": () => import("./../../../src/pages/bfd-player-expansions/americana-sticks.js" /* webpackChunkName: "component---src-pages-bfd-player-expansions-americana-sticks-js" */),
  "component---src-pages-bfd-player-expansions-dark-copper-js": () => import("./../../../src/pages/bfd-player-expansions/dark-copper.js" /* webpackChunkName: "component---src-pages-bfd-player-expansions-dark-copper-js" */),
  "component---src-pages-bfd-player-expansions-dark-mahogany-js": () => import("./../../../src/pages/bfd-player-expansions/dark-mahogany.js" /* webpackChunkName: "component---src-pages-bfd-player-expansions-dark-mahogany-js" */),
  "component---src-pages-bfd-player-expansions-index-js": () => import("./../../../src/pages/bfd-player-expansions/index.js" /* webpackChunkName: "component---src-pages-bfd-player-expansions-index-js" */),
  "component---src-pages-bfd-player-expansions-jazz-brushes-js": () => import("./../../../src/pages/bfd-player-expansions/jazz-brushes.js" /* webpackChunkName: "component---src-pages-bfd-player-expansions-jazz-brushes-js" */),
  "component---src-pages-bfd-player-expansions-jazz-sticks-js": () => import("./../../../src/pages/bfd-player-expansions/jazz-sticks.js" /* webpackChunkName: "component---src-pages-bfd-player-expansions-jazz-sticks-js" */),
  "component---src-pages-bfd-player-expansions-london-70-s-js": () => import("./../../../src/pages/bfd-player-expansions/london-70s.js" /* webpackChunkName: "component---src-pages-bfd-player-expansions-london-70-s-js" */),
  "component---src-pages-bfd-player-expansions-metal-essentials-js": () => import("./../../../src/pages/bfd-player-expansions/metal-essentials.js" /* webpackChunkName: "component---src-pages-bfd-player-expansions-metal-essentials-js" */),
  "component---src-pages-bfd-player-expansions-pop-essentials-js": () => import("./../../../src/pages/bfd-player-expansions/pop-essentials.js" /* webpackChunkName: "component---src-pages-bfd-player-expansions-pop-essentials-js" */),
  "component---src-pages-bfd-player-expansions-vintage-rock-js": () => import("./../../../src/pages/bfd-player-expansions/vintage-rock.js" /* webpackChunkName: "component---src-pages-bfd-player-expansions-vintage-rock-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-company-about-us-html-js": () => import("./../../../src/pages/company/about-us.html.js" /* webpackChunkName: "component---src-pages-company-about-us-html-js" */),
  "component---src-pages-company-press-release-bfd-acquired-by-inmusic-brands-html-js": () => import("./../../../src/pages/company/press-release/bfd-acquired-by-inmusic-brands.html.js" /* webpackChunkName: "component---src-pages-company-press-release-bfd-acquired-by-inmusic-brands-html-js" */),
  "component---src-pages-company-press-release-bfd-website-software-update-portal-expansion-html-js": () => import("./../../../src/pages/company/press-release/bfd-website-software-update-portal-expansion.html.js" /* webpackChunkName: "component---src-pages-company-press-release-bfd-website-software-update-portal-expansion-html-js" */),
  "component---src-pages-company-press-release-js": () => import("./../../../src/pages/company/press-release.js" /* webpackChunkName: "component---src-pages-company-press-release-js" */),
  "component---src-pages-downloads-html-js": () => import("./../../../src/pages/downloads.html.js" /* webpackChunkName: "component---src-pages-downloads-html-js" */),
  "component---src-pages-drum-software-bfd-3-html-js": () => import("./../../../src/pages/drum-software/bfd3.html.js" /* webpackChunkName: "component---src-pages-drum-software-bfd-3-html-js" */),
  "component---src-pages-drum-software-bfd-player-html-js": () => import("./../../../src/pages/drum-software/bfd-player.html.js" /* webpackChunkName: "component---src-pages-drum-software-bfd-player-html-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-html-js": () => import("./../../../src/pages/privacy-policy.html.js" /* webpackChunkName: "component---src-pages-privacy-policy-html-js" */),
  "component---src-pages-refundpolicy-js": () => import("./../../../src/pages/refundpolicy.js" /* webpackChunkName: "component---src-pages-refundpolicy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-terms-of-use-html-js": () => import("./../../../src/pages/terms-of-use.html.js" /* webpackChunkName: "component---src-pages-terms-of-use-html-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-error-js": () => import("./../../../src/templates/error.js" /* webpackChunkName: "component---src-templates-error-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

