import * as React from 'react'
import { Link } from "gatsby"
import {Container, Row, Col} from 'react-bootstrap'
import Year from '../../../common/src/components/year'

export default function BfdFooter() {
	return (
	<>
		<Container fluid style={{backgroundColor:'#E9E9E9', color:"black"}}>
			<Container className='py-5'>
				<Row>
					<Col xs={12} className="footer-imb">
						<img height={40} className="footer-imb-image" src="/logos/inmusic.svg" alt="inmusic" />
						<div className="footer-hr"></div>
					</Col>
				</Row>
				<Row className='align-items-center'>
					<Col xs={12} md={6} className='py-5'>
						<h1 style={{fontSize: 36}} className='bold mb-0'>HOME OF THE WORLD'S PREMIER MUSIC AND AUDIO TECHNOLOGY BRANDS</h1>
					</Col>

					<Col xs={12} md={6} className='py-5'>
						<p style={{color: '#252525', fontSize: 14}}>
							inMusic is a family of leading music technology and consumer electronics brands. Through collaboration and innovation, every inMusic brand has the unique ability to respond dynamically to the demands of its customers, delivering the latest advancements in engineering, design, and technology in the DJ, Music Production, Pro Audio, Musical Instrument, and Consumer Electronics industries.
						</p>
						<h6 style={{fontSize: 12}}>
							<strong>FIND OUT MORE AT <a href="https://www.inmusicbrands.com" target="_blank" style={{color:'black'}}>INMUSICBRANDS.COM</a></strong> 
							<img style={{marginLeft: 4, marginBottom:'3px'}} height="14px" src="/icons/arrow-right.svg" alt="arrow right"/>
						</h6>
					</Col>
				</Row>
			</Container>
		</Container>

		<Container fluid style={{backgroundColor:'#141615', color:"white"}}>
			<Container className='py-5'>
				<Row className='mt-3 footer'>
					<Col>
						<p className="social-list d-flex flex-column flex-sm-row align-items-center">
							<Link to="/">
								<img height="40px" src="/logos/bfd.png" alt="bfd" />
							</Link>
							<div className='d-flex align-items-center mt-3 mt-sm-0'>
								<a href="https://www.facebook.com/bfddrums" target='_blank' rel='noreferrer'>
									<img src="/icons/facebook.svg" alt="facebook" />
								</a>
								<a href="https://twitter.com/bfddrums" target='_blank' rel='noreferrer'>
									<img src="/icons/twitter.svg" alt="twitter" />
								</a>
								<a href="https://www.instagram.com/bfddrums/" target='_blank' rel='noreferrer'>
									<img src="/icons/instagram.svg" alt="instagram" />
								</a>
								<a href="https://www.youtube.com/channel/UCaTHRhZ8fAf6iyyZcG2CeOw/featured" target='_blank' rel='noreferrer'>
									<img src="/icons/youtube.svg" alt="youtube" />
								</a>
							</div>
						</p>
						<p>&copy; <Year /> inMusic, Inc. All Rights Reserved. <a href="https://support.bfddrums.com/support/home" target="_blank">Support</a> | <a href="https://www.inmusicbrands.com/careers" target="_blank">Careers</a> | <Link to='/privacy-policy.html'>Privacy</Link> | <Link to='/terms-of-use.html'>Terms of Use</Link></p>
					</Col>
					<Col md={3}>
						<p className='text-end'>
							<a href="https://www.inmusicbrands.com" target="_blank">
								<img height="40px" className="invert" src="/logos/inmusic.svg" alt="inmusic" />
							</a>
						</p>
						<p className='text-end'>Part of the inMusic family of brands</p>
					</Col>
				</Row>
			</Container>
		</Container>
	</>
	)
}